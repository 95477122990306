<template>
  <div>
    <v-container v-if="loading && !error">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <p>
            Please wait while your lending opportunity information loads
            <v-progress-circular
              class="ml-4"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!loading && !dealActive">
      <v-row justify="center">
        <v-col xs="12" lg="6" class="">
          <div class="text-center">
            <img
              alt="EquityApproved.ca Logo"
              src="../assets/logosvg.svg"
              style="max-width: 450px; margin-top: 10px"
            />
          </div>
          <div class="text-center mt-4">
            <h3>A lender has already been selected!</h3>
          </div>
          <p class="mt-6">
            Thank you for your interest in this lending opportunity. We have
            already received suitable offers from lenders on this lending
            opportunity and the lender has been selected. Stay tuned for future
            opportunities!
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!loading && dealActive && !error">
      <v-row justify="center">
        <v-col xs="12" lg="6" class="">
          <div class="text-center">
            <img
              alt="EquityApproved.ca Logo"
              src="../assets/logosvg.svg"
              style="max-width: 450px; margin-top: 10px"
            />
          </div>
          <div class="text-center mt-4">
            <h3>Lending Opportunity Information</h3>
          </div>
          <p class="mt-6">

            Hi {{ lenderName }}, <br><br>
            Thank you for your interest in this lending opportunity. For more
            information, <strong>please complete the form below</strong> with
            your proposed rate and fee. We will be in touch with full
            application information should the offer be selected.
          </p>
          <br />
          <hr />
          <br />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col xs="12" lg="6" class="">
          <p class="subtitle-2">
            <strong>Location: </strong> {{ dealInfo.city }},
            <span style="text-transform: uppercase">{{
              dealInfo.province
            }}</span>
          </p>

          <p class="caption mt-n2">
            <strong>Notes: </strong> {{ dealInfo.locationNotes }}
          </p>
          <p class="caption mt-n4">
            (Full address and application details will be provided upon interest
            in pursuing lending opporutnity)
          </p>

          <br />

          <p class="subtitle-2">
            <strong>Property Value:</strong>
            {{ dealInfo.propertyValue | formatPrice }}
          </p>

          <p class="caption mt-n2">
            <strong>Notes: </strong>{{ dealInfo.propertyValueNotes }}
          </p>

          <br />

          <p class="subtitle-2">
            <strong>Amounts Owing Against Property:</strong>
            {{ dealInfo.propertyOwing | formatPrice }}
          </p>

          <p class="caption mt-n2">
            <strong>Notes: </strong>{{ dealInfo.propertyOwingNotes }}
          </p>

          <br />

          <p class="subtitle-2">
            <strong>Amount Requested: </strong>
            {{ dealInfo.amountRequested | formatPrice }}
          </p>

          <p class="caption mt-n2">
            <strong>Notes: </strong> {{ dealInfo.amountRequestNotes }}
          </p>

          <br />

          <p class="subtitle-2">
            <strong>Income Notes:</strong>
          </p>

          <p class="caption mt-n2">{{ dealInfo.incomeNotes }}</p>

          <br />

          <p class="subtitle-2">
            <strong>Liability Notes:</strong>
          </p>

          <p class="caption mt-n2">{{ dealInfo.liabilityNotes }}</p>

          <br />

          <p class="subtitle-2">
            <strong>Credit Notes:</strong>
          </p>

          <p class="caption mt-n2">{{ dealInfo.creditNotes }}</p>

          <br />

          <p class="subtitle-2">
            <strong>Summary:</strong>
          </p>

          <p class="caption mt-n2">
            {{ dealInfo.summaryNotes }}
          </p>

          <br />

          <p class="subtitle-2">
            <strong>Exit Strategy:</strong>
          </p>

          <p class="caption mt-n2">{{ dealInfo.exitStrategy }}</p>

          <br />

          <p class="subtitle-2">
            <strong>Deal Ratios: </strong>
          </p>

          <p class="subtitle-2">
            <span style="text-transform: capitalize"
              >{{ dealInfo.ltv }}% Loan To Value</span
            >

            <br />
            <span style="text-transform: capitalize"
              >{{ dealInfo.gds }}% Gross Debt Service Ratio
            </span>

            <br />
            <span style="text-transform: capitalize"
              >{{ dealInfo.tds }}% Total Debt Service Ratio</span
            >
          </p>
          <br />

          <p class="subtitle-2">
            <strong>Proposed Terms: </strong>
          </p>

          <p class="subtitle-2 mt-n2">
            <span style="text-transform: capitalize"
              >Mortgage Position - {{ dealInfo.mortgagePosition }}</span
            >

            <br />
            <span style="text-transform: capitalize"
              >Term - {{ dealInfo.proposedTerm }}</span
            >

            <br />
          </p>
          <p class="caption mt-n2">
            <strong>Notes: </strong>Please reply with your quote after reviewing
            this initial application. Full application and details will be
            provided in further communication shuold your quote be most suitable
            for client.
          </p>

          <br />
          <hr />
          <br />
        </v-col>
      </v-row>

      <v-form
        ref="form"
        v-model="form.rules.valid"
        @submit="submitDealProposal"
      >
        <v-row justify="center">
          <v-col xs="12" lg="6" class="">
            <h3>Interested in this lending opportunity?</h3>
            <p class="caption mt-4 mb-6">
              Please let us know your proposed interest rate and offer. It will
              be considered against other offers to provide options for the
              borrower. If your offer is selected, the full mortgage application
              will be provided to you for further review. Do not hesitate to
              give us a call to discuss at anytime. Thank you for your
              consideration on this lending opportunity and we look forward to
              working with you!
            </p>

            <p style="color: green; font-weight: bold" v-if="alreadyResponded">
              Note: We have already received a response from you on this
              opportunity, though you are able to update your response by
              submitting again.
            </p>

            <div v-if="!form.loading && !form.submitted">
              <v-text-field
                dense
                outlined
                v-model="form.data.proposedLenderRate"
                label="Your Interest Rate Offer (%)"
                type="number"
                :rules="form.rules.required"
              ></v-text-field>
              <v-text-field
                dense
                outlined
                v-model="form.data.proposedLenderFee"
                label="Your Lender Fee Offer (%)"
                type="number"
                :rules="form.rules.required"
              ></v-text-field>
              <v-textarea
                v-model="form.data.comment"
                label="Comments/Questions (optional)"
                outlined
              ></v-textarea>
              <v-btn
                @click="submitDealProposal"
                style="color: white"
                color="green darken-3"
                >Submit Offer</v-btn
              >
              <v-btn
                @click="declineDeal"
                color="deep-orange darken-3"
                style="color: white"
                class="ml-4"
                >Decline Deal</v-btn
              >
            </div>

            <div v-if="form.loading">
              <p>
                Please wait while we submit your offer.
                <v-progress-circular
                  class="ml-4"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </p>
            </div>

            <div v-if="form.submitted && !form.declined">
              <v-alert type="success">
                We have received your response. If your offer is suitable, we
                will follow up with further information and next steps. Thank
                you for your interest!</v-alert
              >
            </div>

            <div v-if="form.submitted && form.declined">
              <v-alert type="success">
                We have received your decline. If you change your mind, you can
                visit this link and resubmit an offer until the lending
                opportunity has been awarded. Thank you for your
                response!</v-alert
              >
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col xs="12" lg="6" class=""> </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-container v-if="error">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <v-card elevation="2" outlined shaped class="pa-4 red"
            >We're sorry, there was an error. Please contact us via
            e-mail.</v-card
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: ["lenderId", "dealId", "uuid"],
  data() {
    return {
      loading: true,
      lenderName: null,
      dealInfo: {},
      dealActive: false,
      error: "",
      alreadyResponded: false,
      dealPivotInfo: {},

      form: {
        rules: {
          valid: false,
          required: [(value) => !!value || "This is a required field."],
        },
        loading: false,
        submitError: false,
        submitted: false,
        declined: false,
        data: {
          proposedLenderRate: "",
          proposedLenderFee: "",
          comment: "",
        },
      },
    };
  },
  methods: {
    async submitDealProposal() {
      let updateDeal = await API().patch("/lenderDealPivotResponse", {
        pivotId: this.dealPivotInfo.id,
        proposedLenderRate: this.form.data.proposedLenderRate,
        proposedLenderFee: this.form.data.proposedLenderFee,
        comment: this.form.data.comment,
        interested: true,
      });

      if (updateDeal.status == 202) {
        this.form.submitted = true;
      } else {
        this.form.submitError = true;
        alert("there was an error submitting deal");
      }
    },
    async declineDeal() {
      let declineConfirm = confirm(
        "Are you sure you want to decline this deal?"
      );

      if (declineConfirm) {
        let updateDeal = await API().patch("/lenderDealPivotResponse", {
          pivotId: this.dealPivotInfo.id,
          proposedLenderRate: this.form.data.proposedLenderRate,
          proposedLenderFee: this.form.data.proposedLenderFee,
          comment: this.form.data.comment,
          interested: false,
        });

        if (updateDeal.status == 202) {
          this.form.submitted = true;
          this.form.declined = true;
        } else {
          this.form.submitError = true;
        }
      }
    },
  },
  async mounted() {
    try {
      let dealInfoResponse = await API().get(
        `/lenderDealInfo/${this.lenderId}/${this.dealId}/${this.uuid}`
      );

      if (dealInfoResponse.status == 200) {
        this.dealInfo = dealInfoResponse.data.dealInfo;
        this.dealPivotInfo = dealInfoResponse.data.dealPivotInfo;
        this.lenderName = dealInfoResponse.data.lenderName;
        if (this.dealInfo.status == "active") {
          this.dealActive = true;
        }
        if (
          this.dealPivotInfo.proposedLenderRate ||
          this.dealPivotInfo.proposedLenderFee ||
          this.dealPivotInfo.comment
        ) {
          this.alreadyResponded = true;
        }
        this.loading = false;
      } else if (dealInfoResponse.status == 204) {
        this.dealActive = false;

        this.loading = false;
      } else {
        this.error =
          "There was an error retrieving the information. Please contact us directly via email.";
        this.loading = false;
      }
    } catch (err) {
      this.error =
        "There was an error retrieving the information. Please contact us directly via email.";
      this.loading = false;
    }
  },
  filters: {
    formatPrice(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
};
</script>

<style>
</style>